<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Create a new Bike </CCardTitle>
                <div class="card-header-actions"></div>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6">
                    <CInput name="number" label="Number" placeholder="Enter the number of the product"
                      description="If you separate numbers with comma's, bikes will be duplicated. E.g. 123,456 will create two bikes."
                      v-model="product.number" />
                  </CCol>

                  <CCol sm="6">
                    <CSelect placeholder="Select" label="Location" :options="locations"
                      :value.sync="product.location_id" />
                  </CCol>

                  <CCol sm="6">
                    <CSelect label="Type" placeholder="select" :options="[
                      { label: 'Bicycle', value: 'bicycle' },
                      { label: 'Accessories', value: ' accessories' },
                      { label: 'E-bike', value: 'e-bike' },
                    ]" :value.sync="product.type" />
                  </CCol>

                  <CCol sm="6">
                    <CSelect label="State" placeholder="select" :options="[
                      { label: 'Correct', value: 'correct' },
                      { label: 'Broken', value: 'broken' },
                      { label: 'Lost', value: 'lost' },
                      { label: 'Basement', value: 'basement' },
                    ]" :value.sync="product.state" />
                  </CCol>
                  <CCol sm="12">
                    <CTextarea name="note" placeholder="Enter a note"
                      description="Enter a note for the product. E.g. required reparation information."
                      v-model="product.note" />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Additional Information</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <div class="additional-fields">
                  <CRow>
                    <CCol sm="12">
                      <CButton variant="outline" type="submit" color="warning" @click="addCustomField()">
                        <CIcon name="cil-playlist-add" /> New Field
                      </CButton>
                    </CCol>

                    <br />

                    <CContainer v-for="(custom_field, index) in product.custom_fields" id="customFieldsContainer">
                      <CRow>
                        <CCol sm="6">
                          <CInput name="name" label="Name" placeholder="Enter the name"
                            v-model="product.custom_fields[index].name" />
                        </CCol>
                        <CCol sm="6">
                          <CInput name="value" label="Value" placeholder="Enter the value"
                            v-model="product.custom_fields[index].value" />
                        </CCol>
                      </CRow>
                    </CContainer>
                  </CRow>
                </div>

                <CCardFooter>
                  <CButton variant="outline" type="submit" color="primary" @click="createProduct">
                    <CIcon name="cil-check-circle" /> Create
                  </CButton>
                </CCardFooter>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import ProductAPI from "/app/src/api/product.js"
import LocationAPI from "/app/src/api/location.js"
import ProductFactory from "/app/src/factories/ProductFactory.js"

export default {
  name: "Create",
  data: () => {
    return {
      productAPI: new ProductAPI(),
      locationAPI: new LocationAPI(),
      product: {},
      locations: [],
      selectedState: { label: "Broken", value: "broken" },
    }
  },
  created: function () {
    this.getLocations()
    this.product = ProductFactory.build("", "", "bicycle", "correct", "")
  },
  methods: {
    getLocations: function () {
      this.$store.dispatch("loading")
      let self = this
      self.locationAPI
        .all()
        .then((result) => {
          this.$store.dispatch("stopLoading")
          for (let i = 0; i < result.length; i++) {
            self.locations.push({ label: result[i].name, value: result[i].id })
          }
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          console.log(error)
          this.$alert.show({ type: "danger", message: error })
        })
    },
    createProduct: function () {
      this.$store.dispatch("loading")
      // Attach customFields to product
      let self = this
      this.productAPI
        .create(self.product)
        .then((result) => {
          this.$store.dispatch("stopLoading")
          self.$router.push({ path: `/manager/products/${result.id}` })
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({ type: "danger", message: error })
        })
    },
    addCustomField: function () {
      this.product.custom_fields.push({ name: "", value: "" })
    },
  },
  watch: {
    "product.type": {
      handler(type) {
        console.log(
          ProductFactory.build(
            this.product.number,
            this.product.location_id,
            type,
            this.product.state,
            this.product.note
          )
        )
        this.product = ProductFactory.build(
          this.product.number,
          this.product.location_id,
          type,
          this.product.state,
          this.product.note
        )
      },
    },
  },
}
</script>

<style scoped>
.additional-fields {
  margin-bottom: 20px;
}
</style>
