var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('CCardTitle',[_vm._v("Create a new Bike ")]),_c('div',{staticClass:"card-header-actions"})],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"6"}},[_c('CInput',{attrs:{"name":"number","label":"Number","placeholder":"Enter the number of the product","description":"If you separate numbers with comma's, bikes will be duplicated. E.g. 123,456 will create two bikes."},model:{value:(_vm.product.number),callback:function ($$v) {_vm.$set(_vm.product, "number", $$v)},expression:"product.number"}})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('CSelect',{attrs:{"placeholder":"Select","label":"Location","options":_vm.locations,"value":_vm.product.location_id},on:{"update:value":function($event){return _vm.$set(_vm.product, "location_id", $event)}}})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('CSelect',{attrs:{"label":"Type","placeholder":"select","options":[
                    { label: 'Bicycle', value: 'bicycle' },
                    { label: 'Accessories', value: ' accessories' },
                    { label: 'E-bike', value: 'e-bike' },
                  ],"value":_vm.product.type},on:{"update:value":function($event){return _vm.$set(_vm.product, "type", $event)}}})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('CSelect',{attrs:{"label":"State","placeholder":"select","options":[
                    { label: 'Correct', value: 'correct' },
                    { label: 'Broken', value: 'broken' },
                    { label: 'Lost', value: 'lost' },
                    { label: 'Basement', value: 'basement' },
                  ],"value":_vm.product.state},on:{"update:value":function($event){return _vm.$set(_vm.product, "state", $event)}}})],1),_c('CCol',{attrs:{"sm":"12"}},[_c('CTextarea',{attrs:{"name":"note","placeholder":"Enter a note","description":"Enter a note for the product. E.g. required reparation information."},model:{value:(_vm.product.note),callback:function ($$v) {_vm.$set(_vm.product, "note", $$v)},expression:"product.note"}})],1)],1)],1)],1),_c('CCard',[_c('CCardHeader',[_c('CCardSubtitle',[_vm._v("Additional Information")])],1),_c('CCardBody',[_c('div',{staticClass:"additional-fields"},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CButton',{attrs:{"variant":"outline","type":"submit","color":"warning"},on:{"click":function($event){return _vm.addCustomField()}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v(" New Field ")],1)],1),_c('br'),_vm._l((_vm.product.custom_fields),function(custom_field,index){return _c('CContainer',{attrs:{"id":"customFieldsContainer"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"6"}},[_c('CInput',{attrs:{"name":"name","label":"Name","placeholder":"Enter the name"},model:{value:(_vm.product.custom_fields[index].name),callback:function ($$v) {_vm.$set(_vm.product.custom_fields[index], "name", $$v)},expression:"product.custom_fields[index].name"}})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('CInput',{attrs:{"name":"value","label":"Value","placeholder":"Enter the value"},model:{value:(_vm.product.custom_fields[index].value),callback:function ($$v) {_vm.$set(_vm.product.custom_fields[index], "value", $$v)},expression:"product.custom_fields[index].value"}})],1)],1)],1)})],2)],1),_c('CCardFooter',[_c('CButton',{attrs:{"variant":"outline","type":"submit","color":"primary"},on:{"click":_vm.createProduct}},[_c('CIcon',{attrs:{"name":"cil-check-circle"}}),_vm._v(" Create ")],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }