import Product from './Product'

export default class Bicycle extends Product {
    constructor(number, location_id, type, state, note) {
        super(number, location_id, type, state, note)
    }

    setCustomFields() {
        this.custom_fields = [
            {'name': 'Frame nr', value: ''},
            {'name': 'Key nr frame', value: ''},
            {'name': 'Key nr. chainlock', value: ''},
            {'name': 'Brand + Type', value: ''},
            {'name': 'Color bike', value: ''},
            {'name': 'Color saddle', value: ''},
            {'name': 'Color tire', value: ''},
            {'name': 'Date first active', value: ''},
          ]
    }
}