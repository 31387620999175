import Bicycle from "../models/Bicycle";
import Accessories from "../models/Accessories";
import EBike from "../models/EBike";

export default class ProductFactory {
  static build(number, location, type, state, note) {
    switch (type) {
      case "bicycle":
        return new Bicycle(number, location, type, state, note);
        break;
      case "accessories":
        return new Accessories(number, location, type, state, note);
        break;
      case "e-bike":
        return new EBike(number, location, type, state, note);
        break;
      default:
        console.log("This model is not available");
        return {};
        break;
    }
  }
}
