export default class Product {
    constructor(number, location_id, type, state, note) {
        this.number = number
        this.location_id = location_id
        this.type = type
        this.state = state
        this.note = note

        this.setCustomFields()
    }

    setCustomFields() {
        this.custom_fields = []
    }
    
}